import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject,  Subscription } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';

import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  PROVIDER_FACEBOOK = 'FACEBOOK';
  users: any;
  fireAuth: any;
  date: any;; data: any;;
  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  private urlBase = 'https://el-dorado-web-default-rtdb.firebaseio.com';

  get currentUserValue() {
    return this.fireAuth;
  }



  constructor(
    private router: Router, public afAuth: AngularFireAuth, public afDB: AngularFireDatabase
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }



  logout() {
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }





  forgotPassword(email: string) {
    this.isLoadingSubject.next(true);
    return true;
  }

  deleteAccount(){
    return this.afAuth.auth.currentUser.delete();
  }



  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  resetPassword(email: string): any {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  loginUser(email: string, password: string): any {
    console.log(email);
    this.isLoadingSubject.next(true);

    return this.afAuth.auth.signInWithEmailAndPassword(email, password).then((data: any) => true).finally(() => {
      this.isLoadingSubject.next(false);
    });
  }

  signupUser(email: string, password: string, nomeResponsavel: any): any {
    const d = new Date();
    this.date = d;
    let month = '';
    if (d.getMonth() === 0) {
      month = '01';
    } else if (d.getMonth() === 1) {
      month = '02';
    } else if (d.getMonth() === 2) {
      month = '03';
    } else if (d.getMonth() === 3) {
      month = '04';
    } else if (d.getMonth() === 4) {
      month = '05';
    } else if (d.getMonth() === 5) {
      month = '06';
    } else if (d.getMonth() === 6) {
      month = '07';
    } else if (d.getMonth() === 7) {
      month = '08';
    } else if (d.getMonth() === 8) {
      month = '09';
    } else if (d.getMonth() === 9) {
      month = '10';
    } else if (d.getMonth() === 10) {
      month = '11';
    } else if (d.getMonth() === 11) {
      month = '12';
    }
    if (d.getDate().toString().length == 1) {
      this.date = '0' + d.getDate();
    } else {
      this.date = d.getDate();
    }
    const n = d.getFullYear() + '-' + month + '-' + this.date;
    this.data = n;

    return this.afAuth.auth.createUserWithEmailAndPassword(email, password).then((newUser: any) => this.afAuth.auth.signInWithEmailAndPassword(email, password).then((authenticatedUser: any) => {
        const uid = authenticatedUser.user.uid;
        const userObject = {
          uid,
          registeredDate: Date.now(),
          key: uid,
          name: nomeResponsavel,
          email,
          password,
          saldo: 10,
          caixa: '0,00'
        };

        newUser.user.updateProfile({
          displayName: nomeResponsavel,
          photoURL: ''
        });

        return this.afDB.list('players').update(uid, userObject);

      }, (error: any) => {
          throw new Error(error.message);
        }
      ), (error: any) => {
        throw new Error(error.message);
    });
  }

  getUserByUid(uid: any) {
    return new Promise((resolve, reject) => {
      const userRef = this.users.child(uid);
      userRef.once('value', function(snap: { val: () => any }) {
        const user = snap.val();
        resolve(user);
      }, function(error: any) {
        reject(error);
      });
    });
  }

  getCurrentUser() {
    return this.afAuth?.auth?.currentUser != undefined;
  }

  getUserData(){
    return this.afAuth?.auth?.currentUser;

  }

  logoutUser() {
    return firebase.auth().signOut();
  }

}
