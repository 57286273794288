import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as firebase from 'firebase';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule, AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AuthService } from './services/auth.service';

const firebaseConfig = {
  apiKey: "AIzaSyCD-li8ON7PiLW238twP_Dcj2UCBIXTPXo",
  authDomain: "el-dorado-web.firebaseapp.com",
  databaseURL: "https://el-dorado-web-default-rtdb.firebaseio.com",
  projectId: "el-dorado-web",
  storageBucket: "el-dorado-web.appspot.com",
  messagingSenderId: "801749535723",
  appId: "1:801749535723:web:b49bf3f217ed804e43e7ae",
  measurementId: "G-M7YZY4N1W6"
};

firebase.initializeApp(firebaseConfig);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig, "angular-auth-firebase"),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
   ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
